@import "../../styles/variables/variables";
.sayHello {
  background-color: $tertiary-color;
  grid-column: full-start / full-end;
  padding: 7rem 0;
  grid-row: 6/7;
  display: grid;
  grid-template-columns:
    [hello-start] minmax(6rem, 1fr) [content-start first-end] repeat(
      12,
      [col-start] minmax(min-content, 10rem) [col-end]
    )
    [content-end] minmax(6rem, 1fr) [hello-end];
  justify-items: center;
  @media screen and (max-width: 400px) {
    grid-template-columns:
      [hello-start] minmax(2rem, 1fr) [content-start first-end] repeat(
        12,
        [col-start] minmax(min-content, 10rem) [col-end]
      )
      [content-end] minmax(2rem, 1fr) [hello-end];
  }
  &__background {
    grid-column: full-start / full-end;
    grid-row: 6/7;
    background-color: transparent;
    position: relative;
    &-element {
      position: absolute;
    }
    &-dottedsmall {
      top: 25%;
      svg circle {
        fill: $tertiary-light-color;
      }
    }
  }
  &__heading-1 {
    grid-column: content-start / content-end;
    color: #fff;
    @media screen and (max-width: 400px) {
      font-size: 4rem;
    }
  }
  &__button {
    padding-top: 3rem;
    grid-column: content-start / content-end;
  }
}
