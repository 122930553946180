.triangle {
  svg path {
    stroke: rgba(255, 255, 255, 0.1);
  }
  @media screen and (max-width: 1100px) {
    svg {
      width: 3.5rem;
    }
  }
  @media screen and (max-width: 900px) {
    svg {
      width: 3.25rem;
    }
  }
}
