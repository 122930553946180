.benzene {
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.1);
    }
  }
  @media screen and (max-width: 1100px) {
    svg {
      width: 2.6rem;
    }
  }
  @media screen and (max-width: 900px) {
    svg {
      width: 2.25rem;
    }
  }
}
