@import "../../styles/variables/variables";
.formelement {
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
  &__inputContainer {
    position: relative;
    padding: 1.5rem 0;
    input {
      display: block;
      background-color: transparent;
      color: #fff;
      outline: none;
      border: none;
      padding-bottom: 0.5rem;
      width: 100%;
      font-family: "DexaPro-medium";
      letter-spacing: 0.03rem;
    }
    textarea {
      display: block;
      background-color: transparent;
      color: #fff;
      outline: none;
      border: none;
      padding-bottom: 0.5rem;
      resize: none;
      width: 100%;
      &::-webkit-scrollbar {
        width: 1rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #1b1e2c;
        // outline: 1px solid slategrey;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #252836;
      }
    }
    &:after {
      content: "";
      width: 100%;
      height: 0.5rem;
      display: inline-block;
      background-color: $primary-color;
      position: absolute;
      z-index: 1;
      //   position: absolute;
      //   bottom: 1rem;
    }
  }
}
