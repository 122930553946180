@import "../../styles/variables/variables";
@import "../../styles/utitlity/utility";
.about {
  background-color: transparent;
  grid-column: full-start / full-end;
  grid-row: 3/4;
  padding-top: 7rem;
  display: grid;
  grid-template-columns: [left-start] 30vw [left-end right-start] 70vw [right-end];
  z-index: 1;
  @media screen and (max-width: 700px) {
    grid-template-columns: unset;
    grid-auto-rows: minmax(min-content, max-content);
    padding-bottom: 2rem;
  }
  &__background {
    background-color: $tertiary-dark-color;
    position: relative;
    grid-column: full-start/full-end;
    grid-row: 3/4;
    &-element {
      position: absolute;
    }
    &-dottedbig {
      top: 5%;
      right: 0;
      svg circle {
        fill: $tertiary-light-color;
      }
      @media screen and (max-width: 1100px) {
        top: 2%;
      }
    }
    &-dottedsmall {
      top: 25%;
      svg circle {
        fill: $tertiary-light-color;
      }
    }
  }
  &__left {
    height: 40rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 1280px) {
      height: 42rem;
    }
    @media screen and (max-width: 1100px) {
      height: 45rem;
    }
    @media screen and (max-width: 1000px) {
      height: 48rem;
    }
    @media screen and (max-width: 800px) {
      height: 49rem;
    }
    @media screen and (max-width: 700px) {
      padding: 0 2rem;
      height: 50rem;
      margin-bottom: 2rem;
    }
  }
  &__right {
    display: grid;
    grid-template-columns: minmax(6rem, 1fr) repeat(6, 1fr) minmax(6rem, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
  }
  &__section {
    grid-column: left-start / right-end;
    margin-bottom: 7rem;
    @media screen and (max-width: 700px) {
      grid-column: unset;
    }
  }
  &-line {
    justify-self: end;
    align-self: center;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 900px) {
      svg {
        width: 4rem;
      }
    }
  }
  &__heading-4 {
    grid-column: 2/-1;
    align-self: center;
    padding: 0;
  }
  &__paragraph {
    grid-column: 2 / 7;
    padding-top: 0.75rem;
    @media screen and (max-width: 700px) {
      grid-column: 2 / 8;
    }
  }
  &__signature {
    width: 18rem;
    height: 9.63rem;
    grid-column: 2/6;
    svg {
      width: 18rem;
      height: 9.63rem;
    }
    svg path {
      fill: $primary-color;
    }
  }
  &__button {
    grid-column: 2/5;
    margin-top: 1rem;
  }
}
