.hexagon {
  @media screen and (max-width: 1100px) {
    svg {
      width: 16rem;
    }
  }
  @media screen and (max-width: 900px) {
    svg {
      width: 14rem;
    }
  }
}
