@import "../../styles/variables/variables";
.header {
  background-color: $tertiary-color;
  color: $primary-color;
  height: 7rem;
  grid-column: full-start / full-end;
  display: grid;
  grid-template-columns:
    [full-start] minmax(10rem, 1fr) [content-start] repeat(
      12,
      minmax(min-content, 10rem)
    )
    [content-end] minmax(10rem, 1fr) [full-end];
  align-items: center;
  &__brand {
    svg {
      height: 4rem;
    }
  }
  &__list {
    list-style: none;

    &--left {
      // margin-right: auto;
      grid-column: content-start / 3;
      @media screen and (max-width: 700px) {
        grid-column: full-start / 3;
        padding-left: 3rem;
      }
    }
    &--right {
      grid-column: 6 / content-end;
      justify-content: end;
      display: grid;
      grid-template-columns: repeat(4, minmax(max-content, 5rem));
      grid-column-gap: 6rem;
      align-items: center;
      letter-spacing: 0.1rem;
      @media screen and (max-width: 1000px) {
        grid-column-gap: 5rem;
      }
      @media screen and (max-width: 800px) {
        grid-column-gap: 4.75rem;
      }
      @media screen and (max-width: 700px) {
        grid-column: 6 / -1;
        grid-template-columns: repeat(2, minmax(max-content, 5rem));
        grid-column-gap: 5rem;
        & > *:not(:first-child) {
          padding-right: 3rem;
        }
      }
    }
  }

  &__item {
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: translateY(-2px);
    }
  }

  &__burger {
    &-container {
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
    height: 0.3rem;
    width: 2.5rem;
    background-color: $primary-color;
    position: relative;
    &::before,
    &::after {
      display: inline-block;
      content: "";
      background-color: $primary-color;
      height: 0.3rem;
      width: 2.5rem;
      position: absolute;
    }
    &::before {
      top: -0.75rem;
    }
    &::after {
      bottom: -0.8rem;
    }
  }
  &__burgerlist {
    height: 9rem;
    position: absolute;
    top: 7rem;
    left: 0;
    width: 100%;
    background-color: $primary-color;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: burgerlist 0.2s ease;
  }
  &__burgeritem {
    cursor: pointer;
    &--1 {
      padding-bottom: 0.5rem;
    }
    &--2 {
      padding-bottom: 0.5rem;
    }
  }
}

@keyframes burgerlist {
  0% {
    transform: translateY(-7rem);
  }
  100% {
    transform: translateY(0);
  }
}
