.dottedbig {
  @media screen and (max-width: 1100px) {
    svg {
      width: 9rem;
    }
  }
  //   @media screen and (max-width: 900px) {
  //     svg {
  //       width: 8rem;
  //     }
  //   }
}
