.dot {
  @media screen and (max-width: 1100px) {
    svg {
      width: 8rem;
    }
  }
  @media screen and (max-width: 900px) {
    svg {
      width: 6rem;
    }
  }
}
