@import "../../styles/variables/variables";
.portfolio {
  background-color: $tertiary-dark-color;
  // height: 50rem;
  grid-column: full-start / full-end;
  grid-row: 5/6;
  padding: 7rem 0 10rem 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    padding: 7rem 0;
  }
  &__link {
    a {
      &:link,
      &:visited {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  &__icons {
    z-index: 100;
    grid-column: content-start / portfolio-end;
    grid-row: 6/7;
    padding-top: 3rem;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 700px) {
      padding-top: 2rem;
    }
    &-icon {
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }
  &__1 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: [left-start] 60vw [left-end right-start] 40vw [right-end];
    margin-bottom: 10rem;
    @media screen and (max-width: 1150px) {
      grid-column-gap: 8rem;
      grid-template-columns: [left-start] 55vw [left-end right-start] 45vw [right-end];
    }
    @media screen and (max-width: 900px) {
      grid-column-gap: 8rem;
      grid-template-columns: [left-start] 56.6vw [left-end right-start] 43.4vw [right-end];
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: unset;
      display: flex;
      flex-direction: column;
      padding: 7rem 0;
      margin-bottom: unset;
    }
  }
  &__2 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: [left-start] 40vw [left-end right-start] 60vw [right-end];
    grid-column-gap: 10rem;
    margin-bottom: 10rem;
    @media screen and (max-width: 1150px) {
      grid-template-columns: [left-start] 45vw [left-end right-start] 55vw [right-end];
      grid-column-gap: unset;
    }
    @media screen and (max-width: 980px) {
      grid-template-columns: [left-start] 43vw [left-end right-start] 57vw [right-end];
    }
    @media screen and (max-width: 900px) {
      grid-template-columns: [left-start] 42vw [left-end right-start] 58vw [right-end];
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: [left-start] 41vw [left-end right-start] 59vw [right-end];
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: unset;
      display: flex;
      flex-direction: column;
      padding: 5rem 0;
    }
    &-left {
      grid-column: left-start / left-end;
      position: relative;
      grid-row: 1/2;
      img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 700px) {
        padding-left: 10rem;
        width: 90%;
        grid-row: unset;
      }
      @media screen and (max-width: 500px) {
        padding-left: 7rem;
      }
    }
    &-right {
      grid-column: right-start / right-end;
      padding-bottom: 4rem;
      display: grid;
      grid-auto-rows: min-content;
      padding-right: 2rem;
      grid-template-columns:
        [portfolio-start] 10rem [content-start first-end] repeat(
          5,
          [col-start] minmax(min-content, 10rem) [col-end]
        )
        [content-end portfolio-end];
      align-content: center;
      @media screen and (max-width: 900px) {
        padding-bottom: 2rem;
      }
      @media screen and (max-width: 700px) {
        grid-template-columns:
          [portfolio-start] 10rem [content-start first-end] repeat(
            5,
            [col-start] minmax(min-content, 10rem) [col-end]
          )
          [content-end] 6.5rem [portfolio-end];
        padding-bottom: 4rem;
      }
      @media screen and (max-width: 500px) {
        grid-template-columns:
          [portfolio-start] 7rem [content-start first-end] repeat(
            5,
            [col-start] minmax(min-content, 10rem) [col-end]
          )
          [content-end] 7rem [portfolio-end];
        padding-bottom: 4rem;
      }
    }
  }
  &__left {
    grid-column: left-start / left-end;
    padding-bottom: 4rem;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns:
      [portfolio-start] 10rem [content-start first-end] repeat(
        5,
        [col-start] minmax(min-content, 10rem) [col-end]
      )
      [content-end portfolio-end];
    align-content: center;
    @media screen and (max-width: 900px) {
      padding-bottom: 2rem;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns:
        [portfolio-start] 10rem [content-start first-end] repeat(
          5,
          [col-start] minmax(min-content, 10rem) [col-end]
        )
        [content-end] 6.5rem [portfolio-end];
      padding-bottom: 4rem;
    }
    @media screen and (max-width: 500px) {
      grid-template-columns:
        [portfolio-start] 7rem [content-start first-end] repeat(
          5,
          [col-start] minmax(min-content, 10rem) [col-end]
        )
        [content-end] 7rem [portfolio-end];
      padding-bottom: 4rem;
    }
  }

  &__right {
    grid-column: right-start / right-end;
    position: relative;
    img {
      // position: absolute;
      // right: -10rem;
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: 700px) {
      padding-left: 10rem;
      width: 90%;
      // align-self: center;
    }
    @media screen and (max-width: 500px) {
      padding-left: 7rem;
    }
  }

  &__background {
    grid-column: full-start / full-end;
    background-color: transparent;
    grid-row: 5/6;
    position: relative;
    &-element {
      position: absolute;
    }
    &-dottedbig {
      top: 2%;
      svg circle {
        fill: $tertiary-light-color;
      }
    }
  }

  &__section {
    grid-column: left-start / right-end;
    padding-bottom: 10rem;
    @media screen and (max-width: 700px) {
      padding-bottom: 5rem;
    }
  }
  &-line {
    grid-column: portfolio-start / first-end;
    grid-row: 2/3;
    justify-self: end;
    margin-right: 1.5rem;
    margin-top: -0.1rem;
    @media screen and (max-width: 900px) {
      svg {
        width: 4rem;
      }
    }
  }
  &__heading-4 {
    grid-column: col-start 1 / col-end 5;
    grid-row: 2/3;
    font-family: "Nunito Sans", sans-serif;
  }
  &__heading-1 {
    grid-column: col-start 1 / col-end 5;
    grid-row: 3/4;
    color: #fff;
    @media screen and (max-width: 700px) {
      padding-bottom: 0.5rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 450px) {
      font-size: 3.5rem;
    }
  }
  &__paragraph {
    grid-column: col-start 1 / col-end 5;
    grid-row: 4/6;
    font-family: "Lagu Sans Regular";
  }
}
