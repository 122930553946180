.heading-1 {
  font-size: 5rem;
  font-family: "DexaPro-bold";
  @media screen and (max-width: 1200px) {
    font-size: 4.75rem;
  }
  @media screen and (max-width: 1100px) {
    font-size: 4.5rem;
  }
}
.heading-3 {
  font-size: 2.75rem;
  font-family: "DexaPro-medium";
  @media screen and (max-width: 1150px) {
    font-size: 2.3rem;
  }
}
.heading-4 {
  font-size: 1.5rem;
  padding-bottom: 2rem;
}
.heading-5 {
  font-size: 1.25rem;
  font-family: "DexaPro-medium";
  letter-spacing: 0.07rem;
}
.detail {
  font-size: 1.75rem;
  font-family: "DexaPro-medium";
  letter-spacing: 0.07rem;
  color: rgba(255, 255, 255, 0.8);
  @media screen and (max-width: 1000px) {
    font-size: 1.38rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.35rem;
  }
  @media screen and (max-width: 750px) {
    font-size: 1.75rem;
  }
}
.paragraph {
  font-size: 1.25rem;
  line-height: 2;
  color: rgba(255, 255, 255, 0.8);
}
.paragraph--big {
  font-size: 1.5rem;
  line-height: 2;
  color: rgba(255, 255, 255, 0.8);
}
