*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;

  @media screen and (max-width: 900px) {
    font-size: 56.25%;
  }
  @media screen and (max-width: 800px) {
    font-size: 50%;
  }
}
body {
  font-family: "Lagu Sans Medium";
  font-size: 1.25rem;
  background-color: #252836;
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #1b1e2c;
    // outline: 1px solid slategrey;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #252836;
  }
}

@font-face {
  font-family: "DexaPro-regular";
  src: url(./fonts//DexaPro-400-Regular.ttf);
}
@font-face {
  font-family: "DexaPro-medium";
  src: url(./fonts/DexaPro-500-Medium.ttf);
}
@font-face {
  font-family: "DexaPro-bold";
  src: url(./fonts/DexaPro-700-Bold.ttf);
}
@font-face {
  font-family: "Lagu Sans Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Lagu Sans Regular"),
    url(./fonts/LaguSans-Regular.woff) format("woff");
}
@font-face {
  font-family: "Lagu Sans Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Lagu Sans Medium"),
    url(./fonts/LaguSans-Medium.woff) format("woff");
}
@font-face {
  font-family: "Lagu Sans SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Lagu Sans SemiBold"),
    url(./fonts/LaguSans-SemiBold.woff) format("woff");
}
